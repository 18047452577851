import peter from "../../images/governance/members/Peter.png";
import ricky from "../../images/governance/members/Ricky.png";
import humayun from "../../images/governance/members/Humayun.png";
import maria from "../../images/governance/members/Maria.png";
import kamal from "../../images/governance/members/Kamal.png";
import ed from "../../images/governance/members/Ed.png";
export const membersArray = [
  {
    name: "Peter Busch",
    icon: peter,
    role: "Chairman",
    title: "Lead Expert DLT Mobility",
    companyName: "Bosch",
    linkedin: "https://www.linkedin.com/in/peter-busch-18286923/",
  },
  {
    name: "Ricky Lamberty",
    icon: ricky,
    role: "Member of the Board",
    title: "Lead Expert Digital Assets",
    companyName: "Bosch",
    linkedin: "https://www.linkedin.com/in/ricky-lamberty/",
  },
  {
    name: "Humayun Sheikh",
    icon: humayun,
    role: "Member of the Board",
    title: "CEO & Founder",
    companyName: "Fetch.ai",
    linkedin: "https://www.linkedin.com/in/humayun-sheikh-4530246a/",
  },
  {
    name: "Maria Minaricova",
    icon: maria,
    role: "Member of the Board",
    title: "Director Business Development",
    companyName: "Fetch.ai",
    linkedin: "https://www.linkedin.com/in/mariaminaricova/",
  },
  {
    name: "Kamal Ved",
    icon: kamal,
    role: "Member of the Board",
    title: "Chief Product Officer",
    companyName: "Fetch.ai",
    linkedin: "https://www.linkedin.com/in/kamalved/",
  },
  {
    name: "Edward FitzGerald",
    icon: ed,
    role: "Member of the Board",
    title: "Chief Technical Officer",
    companyName: "Fetch.ai",
    linkedin: "https://www.linkedin.com/in/edward-fitzgerald-1817593a/",
  },
];
