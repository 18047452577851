import React from "react";
import HeroSection from "../components/governance/HeroSection";
import DesignGoals from "../components/governance/DesignGoals";
import BoardMembers from "../components/governance/boardMembers";
import Layout from "../components/layout";
import CommunitySection from "../components/common/CommunitySection";
import { useBannerWidthContext } from "../context/BannerWidthContext";
import { useBannerDimensions } from "../hooks/useBannerDimensions";

function Governance() {
  const { dispatch } = useBannerWidthContext();
  const bannerRef = useBannerDimensions(dispatch);
  return (
    <div>
      <Layout headerClass="relative">
        <div className="min-h-screen">
          <div className="overflow-hidden">
            <div ref={bannerRef} className="lg:h-[557px]">
              <HeroSection />
            </div>
            <DesignGoals />
            <BoardMembers />
          </div>
        </div>
      </Layout>
    </div>
  );
}
export default Governance;
