import React from "react";
import GuidePrinciple from "../../images/guidingPrinciples.svg";
import GuidePrinciplePhone from "../../images/guidingPrinciples_Phone.svg";

const DesignGoals = () => {
  return (
    <>
      <section className="px-[19.5px] md:px-0 mt-[30px] lg:-mt-32">
        <div className="mx-auto px-[20px] lg:px-[70px] flex justify-center gap-[30px]   w-full container   md:-mt-20  mt-[7rem]  flex-col ">
          <div className="heroCards relative goalsDesktop goalsMobile  z-10  bg-white mx-auto rounded-[20px] flex flex-col lg:flex-row">
            <div className="p-4 xl:w-[50%] lg:w-[45%] w-[50%] h-[50%] lg:h-[0%] "></div>
            <div className="flex p-[40px] lg:p-[5px] flex-col justify-center z-10 w-full mt-[200px] lg:mt-[0px] lg:w-[50%] 2xl:py-[40px] xl:py-[20px]  md:py-[0px] 2xl:pl-[30px]  xl:max-w-[47%] lg:max-w-[50%]">
              <h4 className="font-Lexend lg:text-[36px] text-[25px] font-semibold leading-12 text-left tracking-normal mb-4">
                Design goals
              </h4>
              <div className="mb-6 text-left break-words">
                <p className="lg:text-[16px] text-[14px] ">
                  Our goal is to foster harmonious growth as the ecosystem
                  expands. At the core is a transparent governing nucleus
                  dedicated to creating a collaborative ecosystem that offers
                  fair access to technology and innovation. <br />
                  <br />
                  We&#39;ve established processes to ensure that our governing
                  entity can thrive without relying solely on individual
                  involvement, while still giving a voice to those who
                  contribute to and rely on the ecosystem. To maintain
                  reliability, ownership and control are separated, preserving
                  the foundation&#39;s continuity and promoting balanced
                  governance through py-[18.27px] px-[36px] text-center mx-auto
                  bg-white rounded-[8.524px]a contribution-based system. If
                  you&#39;re interested to learn more about our governance
                  design and principles, please contact us.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="md:py-[100px] md:px-[120px] mt-48 md:mt-0 py-[75px]">
          <h4 className="font-bold  text-[40px] md:text-[46px] text-[#0D283E] text-center mb-4 md:mb-6 pt-2.5 md:pt-0">
            Guiding &nbsp;
            <span className="bg-clip-text text-transparent  bg-gradient-to-r from-[#4789CD] to-[#3CD7F6] ">
              Principles
            </span>
          </h4>
          <div className="text-[#3274B8] uppercase md:hidden px-[32px] font-bold mx-auto grid grid-cols-1 text-[12.177px] gap-[15px] ">
            <div className="py-[18.27px] px-[36px] text-center mx-auto  bg-white rounded-[8.524px]">
              CONTRIBUTION BASED MECHANISM
            </div>
            <div className="py-[18.27px] px-[36px] text-center mx-auto  bg-white rounded-[8.524px]">
              CONTRIBUTION BASED MECHANISM
            </div>

            <div className="py-[18.27px] px-[36px] text-center mx-auto  bg-white rounded-[8.524px]">
              CONTRIBUTION BASED MECHANISM
            </div>
          </div>
          <div className="mx-auto">
            <img
              src={GuidePrinciple}
              className="mx-auto -mt-40 w-[996px] object-cover md:block hidden"
              alt="mission 3"
            />
            <img
              src={GuidePrinciplePhone}
              className="md:hidden w-[300px] mx-auto object-cover"
              alt="mission 3"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default DesignGoals;
