import React from "react";
import linkeding from "../../images/governance/linkedin.svg";
import "./Member.css";

const Member = ({ icon, name, role, title, companyName, linkedin }) => {
  return (
    <div className="w-[345px] min-h-[326px] text-center">
      <div className="mt-2 rounded-full image-container">
        <img src={icon} alt="member" className="object-cover rounded-full" />
      </div>
      <h4 className="py-2  text-[#0D283E] font-normal  text-[22px]  leading-[150%] ">
        {name}
      </h4>
      <p className="text-[#3274B8] font-semibold text-[16px] uppercase">
        {role}
      </p>
      <p className="text-[14px] font-light leading-[150%] ">
        {title}, {companyName}
      </p>
      <a href={linkedin} target="_blank" rel="noopener noreferrer">
        <img src={linkeding} alt="linkedin" className="mx-auto mt-2" />
      </a>
    </div>
  );
};

export default Member;
