import React from "react";
import Member from "./Member";
import { membersArray } from "./constant";

const BoardMembers = () => {
  return (
    <section className="bg-white md:py-[100px] py-[50px]  md:px-[120px] px-[16px] md:-mt-44 -mb-10">
      <div className="max-w-[1200px] mx-auto  ">
        <h4 className="font-bold text-[40px] md:text-[46px] text-[#0D283E] text-center mb-4 md:mb-6 pt-2.5 md:pt-0">
          Board &nbsp;
          <span className="bg-clip-text text-transparent  bg-gradient-to-r from-[#4789CD] to-[#3CD7F6] ">
            Members
          </span>
        </h4>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:gap-20 gap-[35px] pt-[60px]">
          {membersArray.map(
            ({ icon, name, role, title, companyName, linkedin }, index) => (
              <Member
                key={index}
                name={name}
                icon={icon}
                role={role}
                companyName={companyName}
                title={title}
                linkedin={linkedin}
              />
            )
          )}
        </div>
      </div>
    </section>
  );
};

export default BoardMembers;
